import Icons from 'src/features/shared/components/icons';
import styles from './SocialLinks.module.scss';

import Link from 'next/link';
import { IMenuItem } from '../../types/IMenuItem';

interface Props {
  links: IMenuItem[];
}

const iconMap: Record<string, SVGComponent> = {
  facebook: Icons.Facebook,
  instagram: Icons.Instagram,
  tiktok: Icons.Tiktok,
};

export function SocialLinks({ links }: Props) {
  return (
    <ul className={styles.container}>
      {links.map(({ icon, href, isOpenInNewTab, id }) => {
        const Icon = icon && iconMap[icon];

        if (!Icon) {
          return null;
        }

        return (
          <li key={id}>
            <Link
              href={href}
              target={isOpenInNewTab ? '_blank' : undefined}
              rel="noreferrer"
              className={styles.link}
            >
              <Icon className={styles.icon} />
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
