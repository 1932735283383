import classNames from 'classnames';
import styles from './FooterLink.module.scss';
import { Link } from 'src/features/shared/components/link';
import FlagGB from 'assets/icons/flags/gb.svg';
import { Fragment } from 'react';

export interface IFooterLinkProps {
  href: string;
  label: string;
  isExtraLink?: boolean;
  isOpenInNewTab?: boolean;
  isSmall?: boolean;
  className?: string;
}

function replaceENWithFlag(text: string) {
  const parts = text.split('(EN)');
  return parts.map((part, index) => (
    <Fragment key={index}>
      {part}
      {index < parts.length - 1 && <FlagGB />}
    </Fragment>
  ));
}

export const FooterLink = ({
  href,
  label,
  isExtraLink = false,
  isOpenInNewTab = false,
  isSmall = false,
  className,
}: IFooterLinkProps) => (
  <div
    className={classNames(styles.footerItem, {
      [styles.extraFooterItem]: isExtraLink,
    })}
  >
    <Link
      isHtmlTag={href.startsWith(`/checkout`)}
      className={classNames(
        styles.footerLink,
        {
          [styles.extraFooterLink]: isExtraLink,
          [styles.small]: isSmall,
        },
        className,
      )}
      href={href}
      openInNewTab={isOpenInNewTab}
    >
      {replaceENWithFlag(label)}
    </Link>
  </div>
);
