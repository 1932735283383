import { ProxyClient } from 'src/services/proxy/client';
import { IFooter } from '../../types/IFooter';

export const fetchFooterData = async (lang: string) => {
  const response = await ProxyClient.get<IFooter>(
    `/footer?lang=${encodeURIComponent(lang)}`,
  );

  return response.data;
};
