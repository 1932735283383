'use client';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

export const BreakpointsContext = createContext();

export default function useBreakpoints() {
  const breakpoints = useContext(BreakpointsContext);
  const [currentBreakpoint, setCurrentBreakpoint] = useState();

  const getCurrentBreakpoint = useCallback(() => {
    if (window.innerWidth < breakpoints.sm.value) {
      setCurrentBreakpoint(breakpoints.xs.name);
    } else if (
      window.innerWidth >= breakpoints.sm.value &&
      window.innerWidth < breakpoints.md.value
    ) {
      setCurrentBreakpoint(breakpoints.sm.name);
    } else if (
      window.innerWidth >= breakpoints.md.value &&
      window.innerWidth <= breakpoints.lg.value
    ) {
      setCurrentBreakpoint(breakpoints.md.name);
    } else {
      setCurrentBreakpoint(breakpoints.lg.name);
    }
  }, [setCurrentBreakpoint]);

  const onResize = useCallback(() => {
    getCurrentBreakpoint();
  }, [getCurrentBreakpoint]);

  useEffect(() => {
    getCurrentBreakpoint();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return {
    currentBreakpoint,
    breakpoints,
  };
}
