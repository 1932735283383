import styles from './SecondaryFooterMenu.module.scss';
import { Col, Row } from '@features/shared/components/layout';
import { LegalMenu } from '../legal-menu';
import { AppButtons } from 'src/features/layout/footer/components/app-buttons';
import { LoginLink } from 'src/features/shared/components/login-link';
import { IMenuItem } from '../../types/IMenuItem';

interface Props {
  legalLinks: IMenuItem[];
}

export function SecondaryFooterMenu({ legalLinks }: Props) {
  return (
    <Row>
      <Col className={styles.container}>
        <div className={styles.appAndLogin}>
          <LoginLink isLight />
          <AppButtons />
        </div>
        <LegalMenu links={legalLinks} />
      </Col>
    </Row>
  );
}
