import theme from 'config/theme';
import styles from './index.module.scss';
import { Image } from 'src/features/shared/components/image';
import { Link } from 'src/features/shared/components/link';
import classNames from 'classnames';

const appStore = {
  src: '/static/images/shared/app-store-dark.svg',
  srcSet: '/static/images/shared/app-store-dark.svg',
  alt: 'app-store',
};

const playStore = {
  src: '/static/images/shared/google-play.svg',
  srcSet: '/static/images/shared/google-play.svg',
  alt: 'play-store',
};

export const AppButtons = () => {
  return (
    <div className={styles.buttons}>
      <Link openInNewTab href={theme.app.appStore} className={styles.button}>
        <Image
          className={classNames(styles.marginRightM, styles.appStoreImage)}
          image={appStore}
        />
      </Link>
      <Link openInNewTab href={theme.app.playStore} className={styles.button}>
        <Image className={styles.playStoreImage} image={playStore} />
      </Link>
    </div>
  );
};
