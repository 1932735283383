import styles from './index.module.scss';
import { Row, Col } from '@features/shared/components/layout';
import { IFooterColumn } from '../../types/IFooterColumn';
import { PrimaryFooterColumn } from './primary-footer-column';
import * as Accordion from '@features/shared/components/accordion';

export interface IPrimaryFooterMenuProps {
  columns: IFooterColumn[];
}

export const PrimaryFooterMenu = ({ columns }: IPrimaryFooterMenuProps) => {
  return (
    <Row>
      <Col>
        <Accordion.Root type="single" collapsible className={styles.container}>
          {columns.map((column) => (
            <PrimaryFooterColumn column={column} key={column.id} />
          ))}
        </Accordion.Root>
      </Col>
    </Row>
  );
};
