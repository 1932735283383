'use client';
import styles from './Footer.module.scss';
import { Container } from '@features/shared/components/layout';
import { useFooter } from '../hooks/use-footer';
import { PrimaryFooterMenu } from './primary-footer-menu/PrimaryFooterMenu';
import { SocialLinks } from './social-links';
import { SecondaryFooterMenu } from './secondary-footer-menu';

const imageData = {
  src: '/static/images/sportcity/logos/logo-light-slogan.svg',
  alt: 'Sportcity logo',
};

export const Footer = () => {
  const { data: footer } = useFooter();

  if (!footer) {
    return null;
  }

  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.logoContainer}>
          {/* Using native img element, becuase loaded img is svg  */}
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src={imageData.src}
            alt={imageData.alt}
            className={styles.logo}
          />
        </div>
        <SocialLinks links={footer.socialLinks} />

        <PrimaryFooterMenu columns={footer.columns} />

        <SecondaryFooterMenu legalLinks={footer.legalLinks} />
      </Container>
    </footer>
  );
};
