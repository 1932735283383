'use client';
import { useRouter } from 'next/navigation';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

export const LangContext = React.createContext();

export function LangContextProvider({ children }) {
  const router = useRouter();
  const lang = router.locale;

  return (
    <LangContext.Provider value={{ lang }}>{children}</LangContext.Provider>
  );
}

LangContextProvider.propTypes = {
  children: PropTypes.node,
};

export default function useLang() {
  return useContext(LangContext);
}
