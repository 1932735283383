import { useIntl } from '@domains/i18n';
import styles from './LegalMenu.module.scss';
import { IMenuItem } from '../../types/IMenuItem';
import theme from 'config/theme';
import {
  FontColor,
  FontWeight,
  Small,
} from 'src/features/shared/components/typography';
import { FooterLink } from '../shared/footer-link';

export interface ILegalMenuProps {
  links: IMenuItem[];
}

export const LegalMenu = ({ links }: ILegalMenuProps) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.legalMenuContainer}>
      <ul className={styles.list}>
        {links.map((link) => (
          <li key={link.id}>
            <FooterLink
              href={link.href}
              label={link.label}
              isOpenInNewTab={link.isOpenInNewTab}
              isSmall
              key={link.id}
              className={styles.link}
            />
          </li>
        ))}
        <li>
          <Small fontWeight={FontWeight.Bold} color={FontColor.Neutral03}>
            {formatMessage(`footer.${theme.name}.copyRight`, {
              year: new Date().getFullYear(),
            })}
          </Small>
        </li>
      </ul>
    </div>
  );
};
