'use client';
import { useQuery } from 'react-query';

import { STALE_TIME } from '@features/shared/constants/staleTime';
import useLang from '@utils/hooks/use-lang';

import { fetchFooterData } from '../services/proxy/fetch-footer-data';
import { IFooter } from '../types/IFooter';

export const useFooter = () => {
  const { lang } = useLang();

  return useQuery<IFooter, Error>(
    ['main-footer', lang],
    async () => fetchFooterData(lang),
    {
      staleTime: STALE_TIME,
      cacheTime: STALE_TIME * 1.5,
    },
  );
};
