import styles from './PrimaryFooterColumn.module.scss';
import { H5 } from 'src/features/shared/components/typography';
import { IFooterColumn } from '../../../types/IFooterColumn';
import { FooterLink } from '../../shared/footer-link';
import * as Accordion from '@features/shared/components/accordion';
import useBreakpoints from 'src/utils/hooks/use-breakpoints';

export interface IPrimaryFooterColumnProps {
  column: IFooterColumn;
}

export const PrimaryFooterColumn = ({ column }: IPrimaryFooterColumnProps) => {
  const { currentBreakpoint, breakpoints } = useBreakpoints();
  const isMobile = currentBreakpoint === breakpoints.xs.name;

  const renderLinks = () => (
    <ul className={styles.list}>
      {column.menuItems.map((item) =>
        item.href ? (
          <li key={item.id}>
            <FooterLink
              href={item.href}
              label={item.label}
              isOpenInNewTab={item.isOpenInNewTab}
              key={item.id}
            />
          </li>
        ) : null,
      )}

      {column.extraLinkHref && column.extraLinkLabel ? (
        <li>
          <FooterLink
            href={column.extraLinkHref}
            label={column.extraLinkLabel}
            isExtraLink
          />
        </li>
      ) : null}
    </ul>
  );

  if (!isMobile) {
    return (
      <div className={styles.column}>
        <div className={styles.header}>
          <H5 className={styles.title}>{column.title}</H5>
        </div>
        <div>{renderLinks()}</div>
      </div>
    );
  }

  return (
    <Accordion.Item value={column.id} className={styles.column}>
      <Accordion.Trigger className={styles.header}>
        <H5 className={styles.title}>{column.title}</H5>
        <Accordion.Indicator />
      </Accordion.Trigger>
      <Accordion.Content>{renderLinks()}</Accordion.Content>
    </Accordion.Item>
  );
};
