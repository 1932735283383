import * as RadixAccordion from '@radix-ui/react-accordion';
import { ComponentProps, forwardRef } from 'react';
import styles from './Accordion.module.scss';
import classNames from 'classnames';
import Icons from '../icons';

export const Root = RadixAccordion.Root;

export const Item = RadixAccordion.Item;

export const Header = RadixAccordion.Header;

export const Trigger = forwardRef<
  HTMLButtonElement,
  RadixAccordion.AccordionTriggerProps
>(({ className, ...props }, ref) => {
  return (
    <RadixAccordion.Trigger
      {...props}
      ref={ref}
      className={classNames(className, styles.trigger)}
    />
  );
});

Trigger.displayName = 'AccordionTrigger';

export const Indicator = ({ className, ...props }: ComponentProps<'svg'>) => (
  <Icons.Plus className={classNames(styles.indicator, className)} {...props} />
);

export const Content = forwardRef<
  HTMLDivElement,
  RadixAccordion.AccordionContentProps
>(({ className, ...props }, ref) => {
  return (
    <RadixAccordion.Content
      {...props}
      ref={ref}
      className={classNames(className, styles.content)}
    />
  );
});

Content.displayName = 'AccordionContent';
